

import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-2.svg'
import img3 from '../../images/svg-3.svg'
import img4 from '../../images/svg-4.svg'
import img5 from '../../images/svg-5.svg'
import img6 from '../../images/svg-6.svg'


import angular from '../../images/my-skills/angular.png'
import ionic from '../../images/my-skills/ionic.png'
import react from '../../images/my-skills/react.png'
import laravel from '../../images/my-skills/laravel.png'
import mysql from '../../images/my-skills/mysql.png'
import git from '../../images/my-skills/git.png'







// Imagenes de mis skills
export const imagenesSkills= {
    id: 'skills',
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    mysql:mysql,
    git: git,
}



                                                    // PARA TRADUCCION
// Seption Principal:  #0 
export const personalInformation = {
    hello: "Hola, Soy",
    miName: "Braulio Ovalles",
    profession: "Desarrollador De Software",
    mySkills: "Mis Habilidades",
    websiteinformation: "para mas informacion visita mi pagina web: ",
    website: "http://www.sisboa.com"
}



// Seption NavBar #0 (Cabezal)
export const NavBarEnlaces = {
    Home:"Inicio",
    aboutMe: "Quien Soy?",
    MyProyects: "Mis Proyectos",
    MySkills:"Mis Habilidades",
    ContactMe:"Contacto"
}



//Seption About Me  #02
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headLine: 'Quien soy?',
    description: `Hola!, mi nombre es Braulio Ovalles soy ingeníero de software y desarrollador de aplicaciones moviles con 
    varios años de experiencias. 
    me apasiona la programación desde pequeño y cada día me gusta aprender nuevos retos`,
    buttonLabel: 'Leer Más ...',
    imgStart: false,
    img: img6,
    alt: 'about',
    dark: true,
    primary: true,
    darkText: false
}







// SEPTION:   MY PROYECTS   03
export const titleMyProyects= "Mis Proyectos";


export const myProyectsInfornation = {

speanLandia_Title: 'Video-Chat Gratuito',
speanLandia_Description: 'Habla con personas alrededor de todo el mundo.',

goGym_Title: 'App de Gimnasio',
goGym_Description: 'Para la asignación de rutinas de ejercicios y dietas a usuarios.',

pedidos_Title: 'App de pedidos',
pedidos_Description: 'Con sincronización automática hacia la empresa.',

cobrosTitle: 'App de cobros',
cobros_Description: 'Lleva el control de las facturas y estados de cuentas.',

emisora_Title: 'App Emisora Radial',
emisora_Description: 'Para emisoras radiales que transmiten en vivo.',


shoppingList_Title: 'App Lista de Compra',
shoppingList_Description: 'Ordena tus compras de una forma facil y divertida.',

compraVentas_Title: 'App CompraVentas',
compraVentas_Description: 'Lleva el control de tu empresa.',
}



// Seption my skills 04
export const MySkills_Title = "Mis Habilidades";





/*

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'dsdsds',
    headLine: 'Proyects',
    description: '',
    buttonLabel: 'More Proyects',
    imgStart: false,
    img: img2,
    alt: 'proyects-fhotos',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headLine: 'Mis Habilidades',
    description: `Hello, my name is Braulio Ovalles.
     I am a software and mobile application developer.
     I invite you to see my projects and applications that I have created.`,
    buttonLabel: 'More ...',
    imgStart: false,
    img: img3,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,

    shwSkills: false,
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    git: git,
}
*/
