import React, { useState } from 'react'
import { personalInformation } from '../../Languages/DataSelected'
import Video from '../../videos/video.mp4'
import { Button } from '../ButtonsElements'
import { ArrowForward, ArrowRight, HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from './HeroElements'

export const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

     const {hello, miName, profession, mySkills}  = personalInformation;
    return (
        <HeroContainer >
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>

            <HeroContent>
            <HeroH1> {hello} </HeroH1>
                <HeroH1>{miName} </HeroH1>
                <HeroP>
                     {profession}
                </HeroP>

                <HeroBtnWrapper>
                    <Button
                        to="skills"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary='true'    
                        dark='true'
                        >
                        {mySkills} {hover ? <ArrowForward /> : <ArrowRight />}
                        
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>

        </HeroContainer>
    )
}