import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { HeroSection } from '../components/HeroSection'
import { IinfoSection } from '../components/InfoSection'
import { homeObjOne } from '../Languages/DataSelected'
import { NavBar } from '../components/NavBar'
import { Sidebar } from '../components/Sidebar'
import { Services } from '../components/Services'
import  Footer  from '../components/Footer'
import MySkills from '../components/MySkills'




export const Home = () => {


    const [isOpen, setIsOpen] = useState(false)
    const [consejos, setConsejos] = useState([
        { data: 'Integridad de la información' },
        { data: 'Información ordenada.' },
        { data: 'Backup de tus datos.' },
        { data: 'Control total de como se realizan los procesos.' }])


    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <NavBar toggle={toggle} />
            <HeroSection />
            <IinfoSection {...homeObjOne} />
            <Services  />

           <MySkills/>
            {/* <IinfoSection {...homeObjTwo} consejos={consejos} /> */}
            {/* <IinfoSection {...homeObjThree} shwSkills={true}/> */}

            <Footer />

        </>
    )
}
