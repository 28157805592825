import React, { useEffect, useState } from 'react'
import { NavBarEnlaces } from '../../Languages/DataSelected';
import { CloseIcon, Icon, SedebarContainer, SidebarBtnWrap, SidebarLink, SidebarMenu, SidebarRoute, SidebarWrapper } from './SidebarElements'

import { animateScroll as scroll } from 'react-scroll'
import { NavBtn } from '../NavBar/NavBarElements';



import imgSpanish from '../../images/languages/spanish.png';
import imgEnglish from '../../images/languages/english.png';
import imgItalian from '../../images/languages/italiano.png';


export const Sidebar = ({ isOpen, toggle }) => {
    // toggle es la funcion que cierra y abre el menu

    const { Home, aboutMe, MyProyects, MySkills, ContactMe } = NavBarEnlaces;


    const toggleHome = () => {
        scroll.scrollToTop();
    }



    const [listLanguages, setListLanguages] = useState([
        {
            language: "Español",
            img: imgSpanish,
            value: "1"
        },
        {
            language: "English",
            img: imgEnglish,
            value: "2"
        },
        {
            language: "Italiano",
            img: imgItalian,
            value: "3"
        }]);

    const [languageSelectedDefault, setLanguageSelectedDefault] = useState([{
        language: listLanguages[0].language,
        img: listLanguages[0].img,
        value: listLanguages[0].value
    }])









    useEffect(() => {
        if (localStorage.getItem('SpanishData')) {

            setLanguageSelectedDefault([{
                language: listLanguages[0].language,
                img: listLanguages[0].img,
                value: listLanguages[0].value,
            }])


        } else if (localStorage.getItem('EnglishData')) {

            setLanguageSelectedDefault([{
                language: listLanguages[1].language,
                img: listLanguages[1].img,
                value: listLanguages[1].value,
            }])


        } else if (localStorage.getItem('ItalianData')) {

            setLanguageSelectedDefault([{
                language: listLanguages[2].language,
                img: listLanguages[2].img,
                value: listLanguages[2].value,
            }])


        } else {

            setLanguageSelectedDefault([{
                language: listLanguages[0].language,
                img: listLanguages[0].img,
                value: listLanguages[0].value,
            }])


        }

    }, [])


    // metodo cambiar lenguaje
    const languageSelected = async (valor) => {


        switch (valor) {
            case 1:
                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('SpanishData', 'SpanishData');


                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[0].language,
                    img: listLanguages[0].img,
                    value: listLanguages[0].value,
                }])


                return;
            case 2:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('EnglishData', 'EnglishData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[1].language,
                    img: listLanguages[1].img,
                    value: listLanguages[1].value,
                }])


                return;
            case 3:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('ItalianData', 'ItalianData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[2].language,
                    img: listLanguages[2].img,
                    value: listLanguages[2].value,
                }])


                return;


            default:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('SpanishData', 'SpanishData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[0].language,
                    img: listLanguages[0].img,
                    value: listLanguages[0].value
                }])


                return;
        }







    }



    return (
        <SedebarContainer isOpen={isOpen} >
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>

            {/* new register */}
            <SidebarBtnWrap>
                {/* <Img width={100} height={100} src="../../images/languages/spanish.png" /> */}

                <div className="dropdown">
                    <button className="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img width={50} height={40} src={languageSelectedDefault[0].img} />  {languageSelectedDefault[0].language}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => languageSelected(1)}><img width={50} height={40} src={listLanguages[0].img} /> <a href="home">{listLanguages[0].language}</a></li>
                        <li onClick={() => languageSelected(2)}><img width={50} height={40} src={listLanguages[1].img} /> <a href="home">{listLanguages[1].language}</a></li>
                        <li onClick={() => languageSelected(3)}><img width={50} height={40} src={listLanguages[2].img} /> <a href="home">{listLanguages[2].language}</a></li>
                    </ul>
                </div>


                {/* <NavBtnLink to="/signin">Sign In</NavBtnLink> */}
            </SidebarBtnWrap>



            {/* menu block para mobiles */}
            <SidebarWrapper>
                <SidebarMenu >
                    <SidebarLink
                        onClick={toggle} to="about"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}> {aboutMe}</SidebarLink>

                    <SidebarLink onClick={toggle} to="myproyects"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}> {MyProyects} </SidebarLink>

                    <SidebarLink onClick={toggle} to="skills"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}> {MySkills}</SidebarLink>


                    <SidebarLink onClick={toggle} to="footer"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}> {ContactMe}</SidebarLink>

                </SidebarMenu>
            </SidebarWrapper>

            <SidebarBtnWrap>
                <SidebarRoute to='/' onClick={toggleHome} >{Home}</SidebarRoute>
            </SidebarBtnWrap>







        </SedebarContainer>
    )
}
