import styled from "styled-components";


export const LanguageSelect = styled.select`

`


export const ImgLang = styled.img`
border-radius: 50%;
`

