


import angular from '../images/my-skills/angular.png'
import ionic from '../images/my-skills/ionic.png'
import react from '../images/my-skills/react.png'
import laravel from '../images/my-skills/laravel.png'
import mysql from '../images/my-skills/mysql.png'
import git from '../images/my-skills/git.png'
import geoserver from '../images/my-skills/geoserver.png'
import postgres from '../images/my-skills/postgressql.png'
import mongodb from '../images/my-skills/mongo-db.jpeg'
import nodejs from '../images/my-skills/node-js-express.png'
import apchenginx from '../images/my-skills/apache-nginx.png'
import docker from '../images/my-skills/docker.png'



import {
    personalInformation as personalInformation_Spanish,
    NavBarEnlaces as NavBarEnlaces_Spanish,
    homeObjOne as homeObjOne_Spanish,
    titleMyProyects as titleMyProyects_Spanish,
    myProyectsInfornation as myProyectsInfornation_Spanish,
    MySkills_Title as MySkills_Title_Spanish,

} from './Spanish/Data';

import {
    personalInformation as personalInformation_English,
    NavBarEnlaces as NavBarEnlaces_English,
    homeObjOne as homeObjOne_English,
    titleMyProyects as titleMyProyects_English,
    myProyectsInfornation as myProyectsInfornation_English,
    MySkills_Title as MySkills_Title_English,

} from './English/Data';


import {
    personalInformation as personalInformation_Italian,
    NavBarEnlaces as NavBarEnlaces_Italian,
    homeObjOne as homeObjOne_Italian,
    titleMyProyects as titleMyProyects_Italian,
    myProyectsInfornation as myProyectsInfornation_Italian,
    MySkills_Title as MySkills_Title_Italian,

} from './Italian/Data';





let imagenesSkills_1 = {
    id: 'skills',
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    nodejs:nodejs,
    apchenginx:apchenginx,
    docker:docker,
    postgres:postgres,
    mongodb:mongodb,
    mysql: mysql,
    git: git,
    geoserver:geoserver
}

let personalInformation_1 = '';// Seption Principal:  #0 
let NavBarEnlaces_1 = '';// Seption NavBar #0 (Cabezal)
let homeObjOne_1 = '';//Seption About Me  #02
let titleMyProyects_1 = '';// SEPTION:   MY PROYECTS   03
let myProyectsInfornation_1 = '';
let MySkills_Title_1 = ''; // Seption my skills 04



if (localStorage.getItem('SpanishData')) {
    personalInformation_1 = personalInformation_Spanish;// Seption Principal:  #0 
    NavBarEnlaces_1 = NavBarEnlaces_Spanish;// Seption NavBar #0 (Cabezal)
    homeObjOne_1 = homeObjOne_Spanish;//Seption About Me  #02
    titleMyProyects_1 = titleMyProyects_Spanish;// SEPTION:   MY PROYECTS   03
    myProyectsInfornation_1 = myProyectsInfornation_Spanish;
    MySkills_Title_1 = MySkills_Title_Spanish; // Seption my skills 04

} else if (localStorage.getItem('EnglishData')) {

    personalInformation_1 = personalInformation_English;// Seption Principal:  #0 
    NavBarEnlaces_1 = NavBarEnlaces_English;// Seption NavBar #0 (Cabezal)
    homeObjOne_1 = homeObjOne_English;//Seption About Me  #02
    titleMyProyects_1 = titleMyProyects_English;// SEPTION:   MY PROYECTS   03
    myProyectsInfornation_1 = myProyectsInfornation_English;
    MySkills_Title_1 = MySkills_Title_English; // Seption my skills 04


} else if (localStorage.getItem('ItalianData')) {

    personalInformation_1 = personalInformation_Italian;// Seption Principal:  #0 
    NavBarEnlaces_1 = NavBarEnlaces_Italian;// Seption NavBar #0 (Cabezal)
    homeObjOne_1 = homeObjOne_Italian;//Seption About Me  #02
    titleMyProyects_1 = titleMyProyects_Italian;// SEPTION:   MY PROYECTS   03
    myProyectsInfornation_1 = myProyectsInfornation_Italian;
    MySkills_Title_1 = MySkills_Title_Italian;


} else {

    personalInformation_1 = personalInformation_Spanish;// Seption Principal:  #0 
    NavBarEnlaces_1 = NavBarEnlaces_Spanish;// Seption NavBar #0 (Cabezal)
    homeObjOne_1 = homeObjOne_Spanish;//Seption About Me  #02
    titleMyProyects_1 = titleMyProyects_Spanish;// SEPTION:   MY PROYECTS   03
    myProyectsInfornation_1 = myProyectsInfornation_Spanish;
    MySkills_Title_1 = MySkills_Title_Spanish; // Seption my skills 04

}







// PARA TRADUCCION

export const imagenesSkills = imagenesSkills_1;// Imagenes de mis skills
export const personalInformation = personalInformation_1;// Seption Principal:  #0 
export const NavBarEnlaces = NavBarEnlaces_1;// Seption NavBar #0 (Cabezal)
export const homeObjOne = homeObjOne_1;//Seption About Me  #02
export const titleMyProyects = titleMyProyects_1;// SEPTION:   MY PROYECTS   03
export const myProyectsInfornation = myProyectsInfornation_1;
export const MySkills_Title = MySkills_Title_1; // Seption my skills 04
