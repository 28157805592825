import React from 'react'
import { SkillsContainer, SkillsH1, SkillsWrapper, SkillsCard, SkillsIcon} from './MySkillsEmelents'
import { imagenesSkills, MySkills_Title } from '../../Languages/DataSelected'

// import imgSpanish from '../../images/languages/spanish.png';
// import imgEnglish from '../../images/languages/english.png';
// import imgItalian from '../../images/languages/italiano.png';
// import { LanguageSelect } from '../LanaguagesImages/LanguagesElements';


export const MySkills = ({ lightBg }) => {


    const { angular, react, ionic, git,geoserver, laravel, nodejs, apchenginx, docker, postgres, mysql, mongodb,id } = imagenesSkills;
    // const listImagenes = [
    //     {
    //         language: "Spanish",
    //         img: imgSpanish,
    //         value: "1"
    //     },
    //     {
    //         language: "English",
    //         img: imgEnglish,
    //         value: "2"
    //     },
    //     {
    //         language: "Italiano",
    //         img: imgItalian,
    //         value: "3"
        // }];

    return (
        <>
            <SkillsContainer lightBg={lightBg} id={id}>

                <SkillsH1>{MySkills_Title}</SkillsH1>

                <SkillsWrapper>
                    <SkillsCard>
                        <SkillsIcon src={angular} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={ionic} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={react} />
                    </SkillsCard>



                    <SkillsCard>
                        <SkillsIcon src={laravel} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={nodejs} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={docker} />
                    </SkillsCard>


                    <SkillsCard>
                        <SkillsIcon src={postgres} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={mysql} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={mongodb} />
                    </SkillsCard>



                    <SkillsCard>
                        <SkillsIcon src={apchenginx} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={geoserver} />
                    </SkillsCard>

                    <SkillsCard>
                        <SkillsIcon src={git} />
                    </SkillsCard>
                </SkillsWrapper>
            </SkillsContainer>

        </>
    )

}

export default MySkills
