import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube, FaAngleUp, FaGithub, FaWhatsapp, FaBook } from 'react-icons/fa'
import {
    FooterContainer, FooterWraper, FooterLinkContainer, FooterLinkWraper, FooterLinkItems, FooterLinkTitle, FooterLink,
    SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, TextoH3
} from './FooterElements'

import { animateScroll as scroll } from 'react-scroll'
import { NavBarEnlaces } from '../../Languages/DataSelected'

  const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    const TitleFooter = NavBarEnlaces.ContactMe;


    return (
        <FooterContainer id={'footer'}>
            <FooterWraper>

               
                  <TextoH3>{TitleFooter}</TextoH3>  
               
                <FooterLinkContainer>

                    {/* colum1 */}

                    <FooterLinkWraper>
                        <FooterLinkItems>
                            <FooterLinkTitle>WhatSapp <FaWhatsapp /> </FooterLinkTitle>
                            <FooterLink to="/">+39 375 648 8105</FooterLink>
                        </FooterLinkItems>
                    </FooterLinkWraper>





                    {/* colum1 */}
                    <FooterLinkWraper>
                        <FooterLinkItems>
                            <FooterLinkTitle>E-mail <FaBook /></FooterLinkTitle>
                            <FooterLink to="/"> braulioovalles7d@outlook.com</FooterLink>

                        </FooterLinkItems>
                    </FooterLinkWraper>





                </FooterLinkContainer>





                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            {/* subir al inicio */}
                            <FaAngleUp color='primary' />
                        </SocialLogo>
                        <WebsiteRights>Braulio Ovalles © {new Date().getUTCFullYear()} All rights reserved </WebsiteRights>

                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/sisboa" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>

                            <SocialIconLink href="https://www.instagram.com/sisboa_/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>


                            <SocialIconLink href="https://twitter.com/BraulioOvalles" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>

                            <SocialIconLink href="https://github.com/Braulio07" target="_blank" aria-label="GitHub">
                                <FaGithub />
                            </SocialIconLink>

                            <SocialIconLink href="https://www.youtube.com/channel/UCv0KWlYDQEKR6nF4TH-t0YQ/videos" target="_blank" aria-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>

                            <SocialIconLink href="https://www.linkedin.com/in/braulio-o-2aa064145/" target="_blank" aria-label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink>

                        </SocialIcons>

                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWraper>



        </FooterContainer>
    )
}



export default Footer;