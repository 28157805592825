

import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-2.svg'
import img3 from '../../images/svg-3.svg'
import img4 from '../../images/svg-4.svg'
import img5 from '../../images/svg-5.svg'
import img6 from '../../images/svg-6.svg'


import angular from '../../images/my-skills/angular.png'
import ionic from '../../images/my-skills/ionic.png'
import react from '../../images/my-skills/react.png'
import laravel from '../../images/my-skills/laravel.png'
import mysql from '../../images/my-skills/mysql.png'
import git from '../../images/my-skills/git.png'

// Imagenes de mis skills
export const imagenesSkills = {
    id: 'skills',
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    mysql: mysql,
    git: git,
}



// PARA TRADUCCION
// Seption Principal:  #0 
export const personalInformation = {
    hello: "Hi there",
    miName: "I’m Braulio Ovalles",
    profession: "Software Developer",
    mySkills: "My Skills",
    websiteinformation: "for more information visit my website: ",
    website: "http://www.sisboa.com"
}




// Seption NavBar #0 (Cabezal)
export const NavBarEnlaces = {
    Home: "Home",
    aboutMe: "About me",
    MyProyects: "My projects",
    MySkills: "My Skills",
    ContactMe: "Contacts"
}




//Seption About Me  #02
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headLine: 'About me',
    description: `Hi there! My name is Braulio. I’m a software and app developer with many years of experience.
     Programming has always been my passion and I love pushing myself into new challenges every day!`,
    buttonLabel: 'Read more',
    imgStart: false,
    img: img6,
    alt: 'about',
    dark: true,
    primary: true,
    darkText: false
}






// SEPTION:   MY PROYECTS   03
export const titleMyProyects = "My Projects";


export const myProyectsInfornation = {

    speanLandia_Title: 'Free video chat ',
    speanLandia_Description: 'Speak with people from all around the world',

    goGym_Title: 'App for gyms',
    goGym_Description: 'Workouts and meal plans specific for every user',

    pedidos_Title: 'App for placing an order',
    pedidos_Description: 'With automatic synchronization with the company',

    cobrosTitle: 'Payment App',
    cobros_Description: 'To check invoices and account status.',

    emisora_Title: 'Radio App',
    emisora_Description: 'To play live radio contents',


    shoppingList_Title: 'App for your shopping list',
    shoppingList_Description: 'Your shopping list has never been so easy and fun!',

    compraVentas_Title: 'Buying and selling App',
    compraVentas_Description: 'Keep track of your company from anywhere at any time.',
}



// Seption my skills 04
export const MySkills_Title = "My Skills";










/*

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'dsdsds',
    headLine: 'Proyects',
    description: '',
    buttonLabel: 'More Proyects',
    imgStart: false,
    img: img2,
    alt: 'proyects-fhotos',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headLine: 'Mis Habilidades',
    description: `Hello, my name is Braulio Ovalles.
     I am a software and mobile application developer.
     I invite you to see my projects and applications that I have created.`,
    buttonLabel: 'More ...',
    imgStart: false,
    img: img3,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,

    shwSkills: false,
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    git: git,
}
*/
