import React, { useEffect, useState } from 'react'
import { BtnWrap, Colum1, Colum2, Heading, Img, ImgWrap, InfoContainer, InfoRow, InfoWrapper, SectionCard, Subtitle, TextWrapper, TopLine } from './InfoElements'
import { Button } from '../ButtonsElements'




export const IinfoSection = ({ lightBg, id, imgStart, topLine, lightText, headLine, darkText,
    description, buttonLabel, img, alt, primary, dark, dark2 }) => {


    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Colum1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle darkText={darkText}>  {description} </Subtitle>

                                <BtnWrap>
                                    <Button
                                        to='home'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}

                                    >{buttonLabel}</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Colum1>

                        <Colum2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Colum2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>

        </>
    )
}
