

import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-2.svg'
import img3 from '../../images/svg-3.svg'
import img4 from '../../images/svg-4.svg'
import img5 from '../../images/svg-5.svg'
import img6 from '../../images/svg-6.svg'


import angular from '../../images/my-skills/angular.png'
import ionic from '../../images/my-skills/ionic.png'
import react from '../../images/my-skills/react.png'
import laravel from '../../images/my-skills/laravel.png'
import mysql from '../../images/my-skills/mysql.png'
import git from '../../images/my-skills/git.png'

// Imagenes de mis skills
export const imagenesSkills= {
    id: 'skills',
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    mysql:mysql,
    git: git,
}




                                                    // PARA TRADUCCION
// Seption Principal:  #0 
export const personalInformation = {
    hello: "Ciao, Sono",
    miName: "Braulio Ovalles",
    profession: "Programmatore informatico",
    mySkills: "Le mie competenze",
    websiteinformation: "per maggiori informazioni visita il mio sito web: ",
    website: "http://www.sisboa.com"
}


// Seption NavBar #0 (Cabezal)
export const NavBarEnlaces = {
    Home:"Inizio",
    aboutMe: "Chi sono",
    MyProyects: "I miei progetti",
    MySkills:"Le mie competenze",
    ContactMe:"Contatti"
}



//Seption About Me  #02
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headLine: 'Chi sono',
    description: `Ciao a tutti! Mi chiamo Braulio, sono programmatore informatico e sviluppatore di applicazioni per dispositivi
     mobili con anni di esperienza alle spalle. Sin da quando ero bambino ill mondo della programmazione mi ha 
     sempre appassionato e ogni giorno mi piace mettermi alla prova con nuove sfide!`,
    buttonLabel: 'Leggi di più',
    imgStart: false,
    img: img6,
    alt: 'about',
    dark: true,
    primary: true,
    darkText: false
}





// SEPTION:   MY PROYECTS   03
export const titleMyProyects= "I miei progetti";

export const myProyectsInfornation = {

speanLandia_Title: 'Videochat gratuita',
speanLandia_Description: 'Inizia ora a parlare con persone da tutto il mondo!',

goGym_Title: 'App per le palestre',
goGym_Description: 'Allenamenti e piani alimentari personalizzati.',

pedidos_Title: 'App per la presa degli ordini',
pedidos_Description: 'Sincronizzazione automatica con l’azienda.',

cobrosTitle: 'App per pagamenti',
cobros_Description: 'Permette di monitorare le fatture e i conti.',

emisora_Title: 'App radiofoniche',
emisora_Description: 'Per ascoltare trasmissioni radiofoniche dal vivo.',


shoppingList_Title: 'App per la lista della spesa',
shoppingList_Description: 'Fare la lista della spesa non è mai stato così facile e divertente!.',

compraVentas_Title: 'App per la compravendita',
compraVentas_Description: 'La tua azienda a portata di click.',
}



// Seption my skills 04
export const MySkills_Title = "Le mie competenze";










/*

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'dsdsds',
    headLine: 'Proyects',
    description: '',
    buttonLabel: 'More Proyects',
    imgStart: false,
    img: img2,
    alt: 'proyects-fhotos',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headLine: 'Mis Habilidades',
    description: `Hello, my name is Braulio Ovalles.
     I am a software and mobile application developer.
     I invite you to see my projects and applications that I have created.`,
    buttonLabel: 'More ...',
    imgStart: false,
    img: img3,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,

    shwSkills: false,
    angular: angular,
    ionic: ionic,
    react: react,
    laravel: laravel,
    git: git,
}
*/
