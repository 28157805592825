import styled from "styled-components";



export const SkillsContainer = styled.div`
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #fff;
padding: 30px;
padding-top: 120px;

@media screen and (max-width: 768px){
    /* height: 1100px; */
    height: auto;
}

@media screen and (max-width: 480px){
    /* height: 1300px; */
    height: auto;
}
`




export const SkillsWrapper = styled.div`
max-width: 1000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr ;
align-items: center;
grid-gap: 16px;
padding: 0 50px 50px 0;
height: auto;

@media screen and (max-width:1000px){
    grid-template-columns: 1fr 1fr ;
}

@media screen and (max-width:768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
`



export const SkillsCard = styled.div`
background:  #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
max-height: 160px;
padding: 10px;
box-shadow: 0 1px 3px  rgba(0 ,0, 0, 0.2);
transition: all 0.2s ease-in-out;

&:hover{
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
`


export const SkillsIcon = styled.img`
height: 160px;
max-width: 250px;
margin-bottom: 10px;
padding: 20px;
`


export const SkillsH1 = styled.h1`
font-size: 2.5rem;
color: #010606;
margin-bottom: 64px;

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`

export const SkillsH2 = styled.div`
    font-size: 1rem;
    margin-bottom: 10px;
`
export const SkillsP = styled.div`
    font-size: 1rem;
    text-align: center;
`