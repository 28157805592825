import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons/lib'
import { MobileIcon, Nav, NavBarContainer, NavBtn, NavBtnLink, NavItem, NavLinks, NavLogo, NavMenu } from './NavBarElements'
import { FaBars } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import { FaAngleUp } from 'react-icons/fa'
import { NavBarEnlaces } from '../../Languages/DataSelected'


import imgSpanish from '../../images/languages/spanish.png';
import imgEnglish from '../../images/languages/english.png';
import imgItalian from '../../images/languages/italiano.png';
import { LanguageSelect } from '../LanaguagesImages/LanguagesElements';


import { stateChange } from '../../Languages/DataSelected'


export const NavBar = ({ toggle }) => {
    // toggle es la funcion que cierra y abre el menu


    const [listLanguages, setListLanguages] = useState([
        {
            language: "Español",
            img: imgSpanish,
            value: "1"
        },
        {
            language: "English",
            img: imgEnglish,
            value: "2"
        },
        {
            language: "Italiano",
            img: imgItalian,
            value: "3"
        }]);

    const [languageSelectedDefault, setLanguageSelectedDefault] = useState([{
        language: listLanguages[0].language,
        img: listLanguages[0].img,
        value: listLanguages[0].value
    }])


    const { Home, aboutMe, MyProyects, MySkills } = NavBarEnlaces;
    const [scrollNav, setScrollNav] = useState(false)




    useEffect(() => {
        if (localStorage.getItem('SpanishData')) {

            setLanguageSelectedDefault([{
                language: listLanguages[0].language,
                img: listLanguages[0].img,
                value: listLanguages[0].value,
            }])

        
        } else if (localStorage.getItem('EnglishData')) {
        
            setLanguageSelectedDefault([{
                language: listLanguages[1].language,
                img: listLanguages[1].img,
                value: listLanguages[1].value,
            }])

        
        } else if (localStorage.getItem('ItalianData')) {
        
            setLanguageSelectedDefault([{
                language: listLanguages[2].language,
                img: listLanguages[2].img,
                value: listLanguages[2].value,
            }])

        
        } else {
        
            setLanguageSelectedDefault([{
                language: listLanguages[0].language,
                img: listLanguages[0].img,
                value: listLanguages[0].value,
            }])


        }
        
    }, [])





    const changeNavScroll = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    // detectamos el scroll del usuario
    useEffect(() => {
        window.addEventListener('scroll', changeNavScroll)
    }, [])




    // metodo cambiar lenguaje
    const languageSelected = async (valor) => {

        switch (valor) {
            case 1:
                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('SpanishData', 'SpanishData');


                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[0].language,
                    img: listLanguages[0].img,
                    value: listLanguages[0].value,
                }])


                return;
            case 2:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('EnglishData', 'EnglishData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[1].language,
                    img: listLanguages[1].img,
                    value: listLanguages[1].value,
                }])


                return;
            case 3:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('ItalianData', 'ItalianData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[2].language,
                    img: listLanguages[2].img,
                    value: listLanguages[2].value,
                }])


                return;


            default:

                localStorage.removeItem('SpanishData');
                localStorage.removeItem('EnglishData');
                localStorage.removeItem('ItalianData');
                localStorage.setItem('SpanishData', 'SpanishData');

                setLanguageSelectedDefault([{}]);
                setLanguageSelectedDefault([{
                    language: listLanguages[0].language,
                    img: listLanguages[0].img,
                    value: listLanguages[0].value
                }])


                return;
        }







    }




    return (
        <>

            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav scrollNav={scrollNav}>
                    <NavBarContainer>


                        <NavLogo to="/" onClick={toggleHome}> {Home} </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>

                     




                        {/* Menu */}ƒ
                        <NavMenu>
                            <NavItem>
                                <NavLinks
                                    to="about"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>
                                    {aboutMe}
                                </NavLinks>
                            </NavItem>

                            <NavItem>
                                <NavLinks to="myproyects"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>{MyProyects}</NavLinks>
                            </NavItem>

                            <NavItem>
                                <NavLinks to="skills"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>
                                    {MySkills}
                                </NavLinks>
                            </NavItem>




                        </NavMenu>

                        {/* new register */}
                        <NavBtn>
                            <div className="dropdown">
                                <button className="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img width={50} height={40} src={languageSelectedDefault[0].img} />  {languageSelectedDefault[0].language}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={() => languageSelected(1)}><img width={50} height={40} src={listLanguages[0].img} /> <a href="home">{listLanguages[0].language}</a></li>
                                    <li onClick={() => languageSelected(2)}><img width={50} height={40} src={listLanguages[1].img} /> <a href="home">{listLanguages[1].language}</a></li>
                                    <li onClick={() => languageSelected(3)}><img width={50} height={40} src={listLanguages[2].img} /> <a href="home">{listLanguages[2].language}</a></li>
                                </ul>
                            </div>
                        </NavBtn>



                    </NavBarContainer>

                </Nav>


            </IconContext.Provider>
        </>
    )
}
