import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, ServicesLinkA } from './ServicesElements'


import imgPedidos from '../../images/portafolio/pedidos.png'
import imgCobros from '../../images/portafolio/cobros.png'
import imgCompraventas from '../../images/portafolio/compraventas.png'
import imgEmisora from '../../images/portafolio/emisora.png'
import imgShoppinglist from '../../images/portafolio/shoppinglist.png'
import imgSpeaklandia from '../../images/portafolio/speaklandia.png'
import imgGogym from '../../images/portafolio/gogym.png'

import { myProyectsInfornation, personalInformation, titleMyProyects } from '../../Languages/DataSelected'


export const Services = ({ lightBg, id }) => {

    const { websiteinformation, website } = personalInformation;
    const webInfo = myProyectsInfornation;

    return (
        <>
            <ServicesContainer lightBg={lightBg} id={'myproyects'}>

                <ServicesH1>{titleMyProyects}</ServicesH1>


                <ServicesWrapper>

                    <ServicesCard>
                        <ServicesIcon src={imgSpeaklandia} />
                        <ServicesH2>{webInfo.speanLandia_Title}</ServicesH2>
                        <ServicesP> {webInfo.speanLandia_Description}</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <ServicesIcon src={imgGogym} />
                        <ServicesH2>{webInfo.goGym_Title}</ServicesH2>
                        <ServicesP> {webInfo.goGym_Description}</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <ServicesIcon src={imgPedidos} />
                        <ServicesH2>{webInfo.pedidos_Title}</ServicesH2>
                        <ServicesP> {webInfo.pedidos_Description}</ServicesP>
                    </ServicesCard>


                    <ServicesCard>
                        <ServicesIcon src={imgCobros} />
                        <ServicesH2>{webInfo.cobrosTitle}</ServicesH2>
                        <ServicesP> {webInfo.cobros_Description}</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <ServicesIcon src={imgEmisora} />
                        <ServicesH2>{webInfo.emisora_Title}</ServicesH2>
                        <ServicesP> {webInfo.emisora_Description}</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <ServicesIcon src={imgShoppinglist} />
                        <ServicesH2>{webInfo.shoppingList_Title}</ServicesH2>
                        <ServicesP> {webInfo.shoppingList_Description}</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <ServicesIcon src={imgCompraventas} />
                        <ServicesH2>{webInfo.compraVentas_Title}</ServicesH2>
                        <ServicesP> {webInfo.compraVentas_Description}</ServicesP>
                    </ServicesCard>

                </ServicesWrapper>


                <ServicesH2
                    margingH2={true}
                    colorH2={true}>
                    {websiteinformation}
                </ServicesH2>


                <ServicesH2
                    colorH2={true}>
                    <ServicesLinkA href={website} target="_blank" >{website}</ServicesLinkA>
                </ServicesH2>



            </ServicesContainer>

        </>
    )

}

export default Services
